import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  AssignIcon,
  BatchListContainer,
  ButtonDiv,
  Container,
  DataTable,
  FormContainer,
  FormItem,
  TableContainer,
  Title,
  ViewButton,
} from "./style";
import {
  Button,
  Col,
  Divider,
  Form,
  Input,
  message,
  Row,
  Select,
  Table,
} from "antd";
import { handleBatchCreation } from "../../Api/CommonApi";
import { Option } from "antd/lib/mentions";
import data from "../../../../../data/dtdata.json";
import useTraining from "../../Constant/Container.js";
import { BsWindowSidebar } from "react-icons/bs";
import { UserAddOutlined, EyeOutlined } from "@ant-design/icons";
import { Navigate } from "react-router";
import AssignStudent from "../AssignStudents/index.js";

function CreateBatch() {
  const navigate = useNavigate();
  const [
    trainingCount,
    handleDistrictChange,
    applicationList,
    handleDistrictChangeForApplication,
    handleTableChange,
    pagination,
    handleDistrictChangeForBatch,
    trainingList,
    batchList,
  ] = useTraining();
  const [batchDetails, setBatchDetails] = useState({
    BatchName: "",
    StudentCount: "",
    BatchtrainingName: "",
    district: "",
  });
  function handleSubmit(e) {
    e.preventDefault();
    if (batchDetails.BatchName == "") {
      message.warning("Please enter batch name");
    } else if (batchDetails.StudentCount == "") {
      message.warning("Please enter student count");
    } else if (batchDetails.BatchtrainingName == "") {
      message.warning("Please select training");
    } else {
      const response = handleBatchCreation(batchDetails);
      response
        .then((response) => {
          console.log(response);
          message.success(response.statusText);
          // setBatchDetails({
          //   ...batchDetails,
          //   StudentCount: "",
          //   BatchName: "",
          //   BatchtrainingName: "",
          // });
          setTimeout(() => {
            window.location.reload();
          }, 1000);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }
  function handleTrainingSelect(value) {
    setBatchDetails({ ...batchDetails, BatchtrainingName: value });
  }
  function handleDistrictSelect(value) {
    setBatchDetails({ ...batchDetails, district: value });
  }

  const items = [
    { title: "Batch name", dataIndex: "BatchName" },
    { title: "Training name", dataIndex: "trainingName" },

    {
      title: "District",
      dataIndex: "district",
    },
    { title: "Number of students", dataIndex: "StudentCount" },
    {
      title: "Assign",
      render: (data) => {
        return (
          <AssignIcon
            onClick={() => {
              navigate("/ssc-assign-students", { state: { data } });
            }}
          >
            <UserAddOutlined />
          </AssignIcon>
        );
      },
    },
    {
      title: "View",
      render: (data) => {
        return (
          <ViewButton
            onClick={() => {
              navigate("/ssc-view-batch", { state: { data } });
            }}
          >
            <EyeOutlined />
          </ViewButton>
        );
      },
    },
  ];
  return (
    <>
      <Container>
        <h2>Create batch</h2>
        <FormContainer>
          <Form layout="vertical" onSubmitCapture={handleSubmit}>
            <Row>
              <Col span={12}>
                <FormItem label="Select District">
                  <Select
                    showSearch
                    placeholder="Select district"
                    optionFilterProp="children"
                    onChange={(v, k) => {
                      handleDistrictChangeForBatch(v);
                      handleDistrictSelect(v);
                    }}
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    // value={district}

                    name="district"
                  >
                    {data.map((dis, index) => {
                      return (
                        <Option
                          key={index}
                          value={dis.district_name}
                          // onChange={(e)=>setDistrict(e)}
                        >
                          {dis.district_name}
                        </Option>
                      );
                    })}
                  </Select>
                </FormItem>
              </Col>
              <Col span={12}>
                <FormItem label="Training" required>
                  <Select
                    placeholder="Select training"
                    showSearch
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    value={batchDetails.BatchtrainingName}
                    onChange={handleTrainingSelect}
                  >
                    {trainingList?.map((training) => {
                      return (
                        <Option value={training.id}>
                          {training.trainingName}
                        </Option>
                      );
                    })}
                  </Select>
                </FormItem>
              </Col>
            </Row>
            <Row>
              <Col span={12}>
                <FormItem label="Batch Name" required>
                  <Input
                    placeholder="Enter a batch name"
                    value={batchDetails.BatchName}
                    onChange={(e) =>
                      setBatchDetails({
                        ...batchDetails,
                        BatchName: e.target.value,
                      })
                    }
                    type="text"
                  />
                </FormItem>
              </Col>
              <Col span={12}>
                <FormItem label="Batch size" required>
                  <Input
                    placeholder="Batch size"
                    value={batchDetails.StudentCount}
                    onChange={(e) =>
                      setBatchDetails({
                        ...batchDetails,
                        StudentCount: e.target.value,
                      })
                    }
                    type="number"
                  />
                </FormItem>
              </Col>
            </Row>
            <ButtonDiv>
              <Button
                style={{ width: "150px" }}
                type="primary"
                onClick={handleSubmit}
              >
                Submit
              </Button>
            </ButtonDiv>
          </Form>
        </FormContainer>
        <Divider></Divider>
        <BatchListContainer>
          <Title>Batch List</Title>
        </BatchListContainer>
        <TableContainer>
          <DataTable columns={items} dataSource={batchList}></DataTable>
        </TableContainer>
      </Container>
    </>
  );
}

export default CreateBatch;
